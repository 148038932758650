<template>
  <div style="margin: 0 0 50px 0">
    <title>เมนูข่าวอัพเดท</title>
    <img src="../assets/image/UI Member PHOTO/Banner-01.png" class="img-fluid" alt="Responsive image" style="width: 100%; padding-bottom: 0px"/>

    <v-card style="width: 95%; margin: auto">
      <v-toolbar style="padding: 0px">
        <v-tabs v-model="tab">
          <v-tabs-slider style="background-color: #392193"></v-tabs-slider>

          <!-- <v-tab v-on:click="cssTab(1)" :href="`#tab-1`" :style="listcsstab[0]">
            <h1 style="font-size: 18px; margin: 0; font-weight: bold">รวม</h1>
          </v-tab> -->

          <v-tab v-on:click="cssTab(2)" :href="`#tab-2`" :style="listcsstab[1]">
            <h1 style="font-size: 18px; margin: 0; font-weight: bold">
              โปรโมชั่น
            </h1>
          </v-tab>

          <v-tab v-on:click="cssTab(3)" :href="`#tab-3`" :style="listcsstab[2]">
            <h1 style="font-size: 18px; margin: 0; font-weight: bold">
              ข่าวสาร
            </h1>
          </v-tab>

        </v-tabs>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <!-- <v-tab-item :value="`tab-1`" style="padding-top: 5px; padding-bottom: 5px">
          <v-skeleton-loader v-if="loadingdata.pomotion" v-for="item in [1, 2]" :key="item.id" style="width: 95%; height: 80%; margin: 5px" type="image"></v-skeleton-loader>
          <template v-else v-for="item in pomotion">
            <div style="padding-top: 5px; padding-bottom: 5px" :key="item.id">
              <CCard style="width: 95%; margin: 0 auto">
                <CCardBody>
                  <h1 style="font-size: 15px; color: #392193; font-weight: bold">
                    {{ item.header }}
                  </h1>
                  <p style="font-size: 10px; margin: 0 0 5px 0; color: #8e8d8d">
                    {{ item.datetime }}
                  </p>
                  <img :src="item.image" class="img-fluid"  alt="Responsive image" style="width: 100%; padding-bottom: 0px"/>
                  <p style="font-size: 12px; color: #7f7c7c">
                    {{ item.content }}
                    <a :href="item.link" style="color: #000" ><u>{{ item.textlink }}</u></a>
                  </p>
                </CCardBody>
              </CCard>
            </div>
          </template>

          <v-skeleton-loader v-if="loadingdata.news"  v-for="item in [1, 2]" :key="item.id" style="width: 95%; height: 80%; margin: 5px" type="image" ></v-skeleton-loader>
          <template v-for="item in news">
            <div style="padding-top: 5px; padding-bottom: 5px" :key="item.id">
              <CCard style="width: 95%; margin: 0 auto">
                <CCardBody>
                  <h1 style="font-size: 15px; color: #392193; font-weight: bold" >
                    {{ item.header }}
                  </h1>
                  <p style="font-size: 10px; margin: 0 0 5px 0; color: #8e8d8d">
                    {{ item.datetime }}
                  </p>
                  <img :src="item.image"  class="img-fluid"  alt="Responsive image" style="width: 100%; padding-bottom: 0px"/>
                  <p style="font-size: 12px; color: #7f7c7c">
                    {{ item.content }}
                    <a :href="item.link" style="color: #000" ><u>{{ item.textlink }}</u></a>
                  </p>
                </CCardBody>
              </CCard>
            </div>
          </template>
          <template v-if="news.length == 0 && pomotion.length == 0">
            <CCard style="width: 95%; margin: 0 auto">
              <CCardBody>
                <center>
                  <h1 style="font-size: 12px; color: #8e8d8d; margin: 0">
                    ไม่มีข่าวและโปรโมชั่นใหม่ขณะนี้.
                  </h1>
                </center>
              </CCardBody>
            </CCard>
          </template>
        </v-tab-item> -->

        <v-tab-item :value="`tab-2`" style="padding-top: 5px; padding-bottom: 5px">
          <template v-if="pomotion.length > 0">
            <template v-for="item in pomotion">
              <div style="padding-top: 5px; padding-bottom: 5px" :key="item.id">
                <CCard style="width: 95%; margin: 0 auto">
                  <CCardBody>
                    <a :href="item.link">
                      <img :href="item.link" :src="item.image" class="img-fluid" alt="Responsive image" style="width: 100%; padding-bottom: 0px" />
                    </a>

                    <!-- <a :href="item.link" style="font-size: 15px; color: #8e8d8d; font-weight: bold">
                      {{ item.header }}
                    </a> -->

                    <p style="font-size: 15px; margin: 10px 0 5px 0; color: #392193" v-html="item.content"></p>

                    <p style="font-size: 12px; color: #7f7c7c">
                      <a :href="item.link" style="color: #000"><u>{{ item.textlink }}</u></a>
                    </p>
                  </CCardBody>
                </CCard>
              </div>

            </template>
          </template>
          <template v-else>
            <CCard style="width: 95%; margin: 0 auto">
              <CCardBody>
                <center>
                  <h1 style="font-size: 12px; color: #8e8d8d; margin: 0">
                    ไม่มีโปรโมชั่นใหม่ขณะนี้.
                  </h1>
                </center>
              </CCardBody>
            </CCard>
          </template>
        </v-tab-item>

        <v-tab-item :value="`tab-3`" style="padding-top: 5px; padding-bottom: 5px">
          <template v-if="news.length > 0">
            <template v-for="item in news">
              <div style="padding-top: 5px; padding-bottom: 5px" :key="item.id">
                <CCard style="width: 95%; margin: 0 auto">
                  <CCardBody>
                    <a :href="item.link">
                      <img :href="item.link" :src="item.image" class="img-fluid" alt="Responsive image" style="width: 100%; padding-bottom: 0px"/>
                    </a>

                    <!-- <a :href="item.link" style="font-size: 15px; color: #392193; font-weight: bold" >
                      {{ item.header }}
                    </a> -->

                    <a :href="item.link" style="font-size: 15px; color: #392193; font-weight: bold" v-html="item.header"></a>
         
                    <p style="font-size: 10px; margin: 0 0 5px 0; color: #8e8d8d" >
                      {{ item.datetime }}
                    </p>

                    <p style="font-size: 12px; color: #7f7c7c">
                      <!-- {{ item.content }} -->
                      <a :href="item.link" style="color: #000"><u>{{ item.textlink }}</u></a>
                    </p>
                  </CCardBody>
                </CCard>
              </div>
            </template>
          </template>
          <template v-else>
            <CCard style="width: 95%; margin: 0 auto">
              <CCardBody>
                <center>
                  <h1 style="font-size: 12px; color: #8e8d8d; margin: 0">
                    ไม่มีข่าวใหม่ขณะนี้.
                  </h1>
                </center>
              </CCardBody>
            </CCard>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <template v-if="false">
      <v-toolbar color="cyan" dark flat style="background-color: #4d4d4d; padding: 0px">
        <v-tabs>
          <v-tab v-on:click="changeToHome()" style="padding: 0px; margin: 0 0">
            <center>
              <img src="../assets/image/UI Member PHOTO/Icon-01.png" class="img-fluid" alt="Responsive image" width="30px;" />
              <p style="font-size: 10px; margin: 0 0 0 0; color: #808080"> หน้าหลัก</p>
            </center>
          </v-tab>
          <v-tab>
            <center>
              <img src="../assets/image/UI Member PHOTO/Icon-04.png" class="img-fluid" alt="Responsive image" width="30px;"/>
              <p style="font-size: 10px; margin: 0 0 0 0; color: #808080">แลกของรางวัล</p>
            </center>
          </v-tab>
          <v-tab>
            <center>
              <img src="../assets/image/UI Member PHOTO/Icon-03.png" class="img-fluid" alt="Responsive image" width="30px;"/>
              <p style="font-size: 10px; margin: 0 0 0 0; color: #808080"> การทำงาน</p>
            </center>
          </v-tab>
          <v-tab>
            <center>
              <img src="../assets/image/UI Member PHOTO/Icon-05.png" class="img-fluid" alt="Responsive image" width="30px;"/>
              <p style="font-size: 10px; margin: 0 0 0 0; color: #808080"> ข่าวอัพเดท</p>
            </center>
          </v-tab>
          <v-tab>
            <center>
              <img src="../assets/image/UI Member PHOTO/Icon-02.png" class="img-fluid" alt="Responsive image" width="30px;"/>
              <p style="font-size: 10px; margin: 0 0 0 0; color: #808080">การตั้งค่า</p>
            </center>
          </v-tab>
        </v-tabs>
      </v-toolbar>
    </template>
  </div>
</template>

<script>
import MainChartExample from "./charts/MainChartExample";
import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBrand from "./widgets/WidgetsBrand";

import { freeSet } from "@coreui/icons";
import config from "../config";
const axios = require("axios");

export default {
  name: "Dashboard",
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand,
  },
  data() {
    return {
      selected: "Month",
      name: "คุณ วันเดอร์ ซักสะอาด",
      expiration: "00/00/00000",
      nameUser: "คุณ วันเดอร์ ซักสะอาด",
      codetrueyou: "",
      profile: "",
      checkCode: false,
      checkText: "",

      tab: "tab-1",
      csstabActive: "width: 100%; color:#392193;",
      csstabnonActive: "width: 100%; color:#8E8D8D;",
      listcsstab: [],

      news: [],
      pomotion: [],

      loadingdata: {
        news: true,
        pomotion: true,
      },
    };
  },
  mounted() {
    this.regisModal = this.$store.state.popupregis;
    console.log("this.regisModal", this.regisModal);
    var listkey = Object.keys(this.$store.state.profile);
    if (listkey.length <= 3) {
      this.$router.push("/dashboard?pageww=news");
    }
    // this.$store.state.popupregis = this.$store.state.popupregis_buff
    this.profile = this.$store.state.profile;
    this.listcsstab = [
      this.csstabActive,
      this.csstabnonActive,
      this.csstabnonActive,
    ];

    this.API_pomotion().then((res) => {
      this.pomotion = res.data;
      this.pomotion.reverse();
      this.loadingdata.pomotion = false;
    });
    this.API_news().then((res) => {
      this.news = res.data;
      this.news.reverse();
      this.loadingdata.news = false;
    });
  },
  methods: {
    cssTab(numtab) {
      for (var i = 1; i <= this.listcsstab.length; i++) {
        if (i == numtab) {
          this.listcsstab[i - 1] = this.csstabActive;
        } else {
          this.listcsstab[i - 1] = this.csstabnonActive;
        }
      }
    },
    changeToHome() {
      console.log("..........");
    },
    API_pomotion() {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios
          .get(config.server + "/pomotion", {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },
    API_news() {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios
          .get(config.server + "/news", {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },
  },
};
</script>
